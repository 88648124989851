<template>
	<v-app>
		<div class="wrapper_admin">
			<slot/>
		</div>
		<v-bottom-navigation
		>
			<v-btn value="clients" href="/">
				<v-icon>mdi-home</v-icon>
				<span>Назад</span>
			</v-btn>
			<v-btn value="clients" href="/admin/search" :active=" route.fullPath === '/admin/search' ">
				<v-icon>mdi-magnify</v-icon>
				<span>Поиск</span>
			</v-btn>
			<v-btn value="portfolio" href="/admin/reserved" :active=" route.fullPath === '/admin/reserved' ">
				<v-icon>mdi-lock-outline</v-icon>
				<span>Резерв</span>
			</v-btn>
		
		</v-bottom-navigation>
	</v-app>


</template>

<script setup lang="ts">

const route = useRoute();
</script>

<style scoped>
.wrapper_admin {
	margin-bottom: 100px;
}
</style>